import { useContext, useMemo } from "react";
import { Outlet, useLocation, useParams, useNavigate } from "react-router-dom";

import { Alert, Breadcrumb, Menu, MenuProps, Skeleton } from "antd";

import { HomeOutlined } from "@ant-design/icons";

import AdaptorsService from "../../../services/Adaptors";
import { UserContext } from "../../../context/UserContext";
import { useQuery } from "@tanstack/react-query";

const items: MenuProps["items"] = [
  {
    label: "Activity",
    key: "activity",
  },
  {
    label: "Clients",
    key: "clients",
  },
  {
    label: "Parameters",
    key: "parameters",
  },
];

const Adaptor = () => {
  const { token } = useContext(UserContext);

  const { adaptorName } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const selectedKey = useMemo(() => {
    return pathname.split("/").slice(-1);
  }, [pathname]);

  const {
    error,
    data: adaptor,
    isFetching,
  } = useQuery({
    queryKey: ["AdaptorsService.getAdaptor", adaptorName],
    queryFn: () => AdaptorsService.getAdaptor(token!, adaptorName!),
    enabled: !!adaptorName,
  });

  if (isFetching) {
    return <Skeleton />;
  }

  if (error) {
    return <Alert message="Could not find the selected adaptor" type="error" />;
  }

  return (
    <>
      <Breadcrumb
        style={{ marginBottom: "1rem" }}
        items={[
          {
            title: <HomeOutlined />,
            onClick: () => navigate("/"),
          },
          {
            title: "Adaptors",
            onClick: () => navigate("/adaptors"),
          },
          {
            title: adaptor?.name,
          },
        ]}
      />

      <Menu
        selectedKeys={selectedKey}
        onClick={({ key }) => {
          navigate(pathname.substring(0, pathname.lastIndexOf("/") + 1) + key);
        }}
        mode="horizontal"
        items={items}
        style={{ marginBottom: "1rem" }}
      />

      {adaptor ? <Outlet context={{ adaptor }} /> : <Skeleton />}
    </>
  );
};

export default Adaptor;
