import { useContext, useMemo } from "react";
import { useOutletContext } from "react-router-dom";
import { AdaptorsContext } from "../../context/AdaptorsContext";

import ParametersEditor from "../../components/ParametersEditor";
import ClientsService from "../../services/Clients";
import { UserContext } from "../../context/UserContext";

const ClientAdaptorParameters = () => {
  const { token } = useContext(UserContext);

  const { getAdaptor } = useContext(AdaptorsContext);

  const { client, adaptor } = useOutletContext<any>();

  const currentAdaptor = useMemo(() => {
    const { id, name } = adaptor || {};
    return getAdaptor({ id, name });
  }, [adaptor, getAdaptor]);

  return (
    <>
      <ParametersEditor
        parameters={client?.parameters || {}}
        allowedKeys={currentAdaptor?.clientKeys || []}
        onUpdate={(value) =>
          ClientsService.setAdaptorParameters(
            token!,
            client.id,
            adaptor.name,
            value
          )
        }
      />
    </>
  );
};

export default ClientAdaptorParameters;
