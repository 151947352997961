import React, { useContext, useMemo } from "react";
import { MenuProps, Menu } from "antd";

import { UserOutlined } from "@ant-design/icons";
import { UserContext } from "../../../context/UserContext";

const Topbar = () => {
  const { user, signOut } = useContext(UserContext);

  const items: MenuProps["items"] = useMemo(
    () => [
      {
        key: "user",
        icon: React.createElement(UserOutlined),
        label: user.username,
        children: [
          {
            key: "user-signout",
            label: "Sign out",
            onClick: signOut,
          },
        ],
      },
    ],
    [user, signOut]
  );

  return (
    <Menu
      mode="horizontal"
      style={{ justifyContent: "flex-end" }}
      items={items}
    />
  );
};

export default Topbar;
