import {
  CheckCircleOutlined,
  QuestionCircleOutlined,
  MinusCircleOutlined,
  InfoCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { Space } from "antd";

const AdaptorUsageLegend = () => (
  <Space size="large">
    <Space>
      <InfoCircleOutlined style={{ color: "#006d75" }} /> Installed (never used)
    </Space>
    <Space>
      <CheckCircleOutlined style={{ color: "#237804" }} /> Installed (used &lt;
      30 days)
    </Space>
    <Space>
      <QuestionCircleOutlined style={{ color: "#fadb14" }} /> Installed (used
      &lt; 90 days)
    </Space>
    <Space>
      <MinusCircleOutlined style={{ color: "#a8071a" }} /> Installed (used &gt;
      90 days)
    </Space>
    <Space>
      <ExclamationCircleOutlined style={{ color: "#ffa940" }} /> Uninstalled
      (used &lt; 30 days)
    </Space>
  </Space>
);

export default AdaptorUsageLegend;
