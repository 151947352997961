import { useContext, useMemo } from "react";
import { useOutletContext } from "react-router-dom";

import { useQuery } from "@tanstack/react-query";

import { Card, Col, Row, Typography } from "antd";

import { UserContext } from "../../context/UserContext";

import BarChart from "../../components/charts/BarChart";

import ChartsService from "../../services/Charts";

import { calculatePercentages } from "../../Utils";

const AdaptorActivity = () => {
  const { adaptor } = useOutletContext<any>();

  const { token } = useContext(UserContext);

  const { data: activitiesByClient } = useQuery({
    queryKey: [
      "ChartsService.getAllActivitiesForAdaptorByClients",
      adaptor?.name,
    ],
    queryFn: () =>
      ChartsService.getAllActivitiesForAdaptorByClients(token!, adaptor.name),
    enabled: !!adaptor,
  });

  const { data: errorsByClient } = useQuery({
    queryKey: [
      "ChartsService.getFailedActivitiesForAdaptorByClients",
      adaptor?.name,
    ],
    queryFn: () =>
      ChartsService.getFailedActivitiesForAdaptorByClients(
        token!,
        adaptor.name
      ),
    enabled: !!adaptor,
  });

  const errorsByClientWithPercentages = useMemo(
    () =>
      calculatePercentages(
        activitiesByClient!,
        errorsByClient!,
        "count",
        "percentage",
        (v1, v2) => v1.name === v2.name
      ),
    [activitiesByClient, errorsByClient]
  );

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Card loading={activitiesByClient === undefined}>
            <Typography.Title level={5} style={{ marginTop: 0 }}>
              Total Business Activities by Client
            </Typography.Title>

            <BarChart data={activitiesByClient} entityType="Activities" />
          </Card>
        </Col>
        <Col span={12}>
          <Card loading={errorsByClientWithPercentages === undefined}>
            <Typography.Title level={5} style={{ marginTop: 0 }}>
              Failed Business Activities by Client
            </Typography.Title>
            <BarChart
              data={errorsByClientWithPercentages}
              entityType="Activities"
              percentage="percentage"
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default AdaptorActivity;
