import { ReactNode, useCallback, useMemo } from "react";

import { Tabs } from "antd";

const TopOrAllTabs = ({
  data,
  count,
  field,
  component,
}: {
  count: number;
  data: null | undefined | Record<any, any>[];
  field: string;
  component: (data: null | undefined | Record<any, any>[]) => ReactNode;
}) => {
  const sortFn = useCallback(
    (a: Record<any, any>, b: Record<any, any>) =>
      (b[field] as unknown as number) - (a[field] as unknown as number),
    [field]
  );

  const all = useMemo(() => {
    if (Array.isArray(data) && sortFn) {
      return [...data].sort(sortFn);
    }
    return data;
  }, [data, sortFn]);

  const topN = useMemo(() => {
    if (Array.isArray(all)) {
      return all.slice(0, count);
    }
    return all;
  }, [all, count]);

  const tabs = useMemo(
    () => [
      {
        label: `Top ${count}`,
        key: "top",
        children: component(topN),
      },
      {
        label: `All records`,
        key: "all",
        children: component(all),
      },
    ],
    [component, count, topN, all]
  );

  return <Tabs defaultActiveKey="0" items={tabs} />;
};

export default TopOrAllTabs;
