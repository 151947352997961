import { Adaptor } from "./Adaptors";
import { get, post, put, remove } from "./http";

export type Client = {
  id: number;
  name: string;
  usage?: number;
};

export type ClientDetails = {
  id: number;
  name: string;
  adaptors: Array<Adaptor>;
  parameters: Record<string, string>;
};

export type CreateClientRequest = {
  name: string;
  username: string;
  password: string;
  clientId: string;
  clientSecret: string;
  baseUrl: string;
  authUrl: string;
  grantType: string;
  namespace: string;
};

export type CreateClientResponse = {
  name: string;
  clientId: string;
  clientSecret: string;
};

export type Map = {
  client: Client;
  adaptor: Adaptor;
};

type ClientsServiceType = {
  getClients: (token: string) => Promise<Array<Client>>;
  getClient: (token: string, id: number) => Promise<ClientDetails>;
  createClient: (
    token: string,
    req: CreateClientRequest
  ) => Promise<CreateClientResponse>;

  getClientsAdaptorsMap: (token: string) => Promise<Array<Map>>;

  setAdaptorVisibility: (
    token: string,
    clientId: number,
    adaptorName: string,
    visible: boolean
  ) => Promise<ClientDetails>;

  setAdaptorGrant: (
    token: string,
    clientId: number,
    adaptorName: string,
    visible: boolean
  ) => Promise<ClientDetails>;

  setAdaptorParameters: (
    token: string,
    clientId: number,
    adaptorName: string,
    parameters: Record<string, string>
  ) => Promise<ClientDetails>;
};

const ClientsService: ClientsServiceType = {
  getClients: (token) => get(token)(`/admin/clients`),
  getClient: (token, id) => get(token)(`/admin/clients/${id}`),
  createClient: (token, req) => post(token)(`/admin/clients`, req),
  getClientsAdaptorsMap: (token) => get(token)("/admin/clients/map"),

  setAdaptorVisibility: (
    token: string,
    clientId: number,
    adaptorName: string,
    visible: boolean
  ) =>
    (visible ? put : remove)(token)(
      `/admin/clients/${clientId}/adaptors/${adaptorName}/visible`,
      {}
    ),

  setAdaptorGrant: (
    token: string,
    clientId: number,
    adaptorName: string,
    granted: boolean
  ) =>
    (granted ? put : remove)(token)(
      `/admin/clients/${clientId}/adaptors/${adaptorName}/grant`,
      {}
    ),

  setAdaptorParameters: (
    token: string,
    clientId: number,
    adaptorName: string,
    parameters: Record<string, string>
  ) =>
    put(token)(
      `/admin/clients/${clientId}/adaptors/${adaptorName}/parameters`,
      parameters
    ),
};
export default ClientsService;
