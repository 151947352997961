import { Client } from "./Clients";
import { get, put } from "./http";

export type Adaptor = {
  id: number;
  name: string;
  title: string;

  initialized?: boolean;
  installed?: boolean;
  visible?: boolean;
  usage?: number;

  adaptorKeys?: Array<string>;
  clientKeys?: Array<string>;

  granted?: boolean;
};

export type AdaptorDetails = {
  id: number;
  name: string;
  title: string;

  clients: Array<Client>;
  parameters: Record<string, string>;
};

type AdaptorsServiceType = {
  getAdaptors: (token: string) => Promise<Array<Adaptor>>;
  getAdaptor: (token: string, name: string) => Promise<AdaptorDetails>;
  setParameters: (
    token: string,
    name: string,
    parameters: Record<string, string>
  ) => Promise<AdaptorDetails>;
};

const AdaptorsService: AdaptorsServiceType = {
  getAdaptors: (token) => get(token)(`/admin/adaptors`),
  getAdaptor: (token, name) => get(token)(`/admin/adaptors/${name}`),

  setParameters: (
    token: string,
    name: string,
    parameters: Record<string, string>
  ) => put(token)(`/admin/adaptors/${name}/parameters`, parameters),
};

export default AdaptorsService;
