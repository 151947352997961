import { useContext, useMemo } from "react";
import { Outlet, useLocation, useParams, useNavigate } from "react-router-dom";

import { Alert, Breadcrumb, Menu, MenuProps, Skeleton } from "antd";

import { HomeOutlined } from "@ant-design/icons";

import ClientsService from "../../../services/Clients";
import { useQuery } from "@tanstack/react-query";
import { UserContext } from "../../../context/UserContext";

const items: MenuProps["items"] = [
  {
    label: "Activity",
    key: "activity",
  },
  {
    label: "Adaptors",
    key: "adaptors",
  },
];

const Client = () => {
  const { token } = useContext(UserContext);

  const { clientId } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const selectedKey = useMemo(() => {
    return pathname.split("/").slice(-1);
  }, [pathname]);

  const {
    error,
    data: client,
    refetch: doRefetchClient,
    isFetching,
  } = useQuery({
    queryKey: ["ClientsService.getClient", clientId],
    queryFn: () => ClientsService.getClient(token!, Number(clientId!)),
    enabled: !!clientId,
  });

  if (isFetching) {
    return <Skeleton />;
  }

  if (error) {
    return <Alert message="Could not find the selected client" type="error" />;
  }

  return (
    <>
      <Breadcrumb
        style={{ marginBottom: "1rem" }}
        items={[
          {
            title: <HomeOutlined />,
            onClick: () => navigate("/"),
          },
          {
            title: "Clients",
            onClick: () => navigate("/clients"),
          },
          {
            title: client?.name,
          },
        ]}
      />

      <Menu
        selectedKeys={selectedKey}
        onClick={({ key }) => {
          navigate(pathname.substring(0, pathname.lastIndexOf("/") + 1) + key);
        }}
        mode="horizontal"
        items={items}
        style={{ marginBottom: "1rem" }}
      />

      {client ? <Outlet context={{ client, doRefetchClient }} /> : <Skeleton />}
    </>
  );
};

export default Client;
