import { useContext, useMemo } from "react";
import { Outlet, useLocation, useParams, useNavigate } from "react-router-dom";

import { Alert, Breadcrumb, Menu, MenuProps, Skeleton } from "antd";

import { HomeOutlined } from "@ant-design/icons";

import ClientsService from "../../../services/Clients";
import { useQuery } from "@tanstack/react-query";
import { UserContext } from "../../../context/UserContext";
import AdaptorsService from "../../../services/Adaptors";

const items: MenuProps["items"] = [
  {
    label: "Activity",
    key: "activity",
  },
  {
    label: "Parameters",
    key: "parameters",
  },
];

const ClientAdaptor = () => {
  const { token } = useContext(UserContext);

  const { clientId, adaptorName } = useParams();

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const selectedKey = useMemo(() => {
    return pathname.split("/").slice(-1);
  }, [pathname]);

  const {
    error: clientError,
    data: client,
    isFetching: isClientFetching,
  } = useQuery({
    queryKey: ["ClientsService.getClient", clientId],
    queryFn: () => ClientsService.getClient(token!, Number(clientId!)),
    enabled: !!clientId,
  });

  const {
    error: adaptorError,
    data: adaptor,
    isFetching: isAdaptorFetching,
  } = useQuery({
    queryKey: ["daptorsService.getAdaptor", adaptorName],
    queryFn: () => AdaptorsService.getAdaptor(token!, adaptorName!),
    enabled: !!adaptorName,
  });

  if (isClientFetching || isAdaptorFetching) {
    return <Skeleton />;
  }

  if (clientError) {
    return <Alert message="Could not find the selected client" type="error" />;
  }

  if (adaptorError) {
    return <Alert message="Could not find the selected adaptor" type="error" />;
  }

  return (
    <>
      <Breadcrumb
        style={{ marginBottom: "1rem" }}
        items={[
          {
            title: <HomeOutlined />,
            onClick: () => navigate("/"),
          },
          {
            title: "Clients",
            onClick: () => navigate("/clients"),
          },
          {
            title: client?.name,
          },
          {
            title: "Adaptors",
            onClick: () => navigate(`/clients/${client?.id}/adaptors`),
          },
          {
            title: adaptor?.title || adaptor?.name,
          },
        ]}
      />

      <Menu
        selectedKeys={selectedKey}
        onClick={({ key }) => {
          navigate(pathname.substring(0, pathname.lastIndexOf("/") + 1) + key);
        }}
        mode="horizontal"
        items={items}
        style={{ marginBottom: "1rem" }}
      />

      {client && adaptor ? (
        <Outlet context={{ client, adaptor }} />
      ) : (
        <Skeleton />
      )}
    </>
  );
};

export default ClientAdaptor;
