import { useMemo } from "react";

import { Alert, Empty } from "antd";
import { Column } from "@ant-design/charts";

import { intervals, monthLabelFormatter } from "../../Utils";

interface MonthlyBarChartProps {
  data: null | undefined | Record<any, any>[];
  entityType: string;

  label?: string;
  count?: string;
  percentage?: string;
}

const MonthlyBarChart = ({
  data,
  entityType,
  label,
  count,
  percentage,
}: MonthlyBarChartProps) => {
  const enrichedData = useMemo(() => {
    if (!Array.isArray(data)) {
      return [];
    }

    const points = data.map((d) => d[label || "month"]).sort();

    if (points.length === 0) {
      return [];
    }

    return intervals(points[0], points[points.length - 1]).reduce(
      (acc: Array<any>, pt: string) => {
        const filtered = data.filter((f) => f[label || "month"] === pt);

        return [
          ...acc,
          ...(filtered.length > 0
            ? filtered
            : [{ [label || "month"]: pt, [count || "count"]: 0 }]),
        ];
      },
      []
    );
  }, [data, label, count]);

  const config = useMemo(
    () => ({
      data: enrichedData,
      xField: label || "month",
      yField: count || "count",
      ...(percentage
        ? {
            label: {
              textBaseline: (d: Record<any, any>) => (d[percentage] > 20 ? 'top' : 'bottom'),
              fill: (d: Record<any, any>) => (d[percentage] > 20 ? '#fff' : '#000'),

              text: (d: Record<any, any>) =>
                `${((d[percentage] || 0.0) * 1.0).toFixed(2)}%`,
            },
          }
        : {}),
      tooltip: {
        name: entityType,
        field: count || "count",
      },
      axis: {
        x: {
          labelFormatter: monthLabelFormatter,
        },
      },
    }),
    [entityType, count, label, percentage, enrichedData]
  );

  return (
    <>
      {Array.isArray(data) ? (
        data.length === 0 ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ) : (
          <Column {...config} />
        )
      ) : (
        <Alert message="Could not load chart data" type="error" />
      )}
    </>
  );
};

export default MonthlyBarChart;
