import { ReactNode } from "react";
import { Layout, theme } from "antd";
import { Outlet } from "react-router-dom";

import Logo from "./components/Logo";
import Sidebar from "./components/Sidebar";
import Topbar from "./components/Topbar";

const Root = ({ children }: { children?: ReactNode }) => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  return (
    <Layout hasSider style={{ minHeight: "100vh" }}>
      <Layout.Sider
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <Logo />
        <Sidebar />
      </Layout.Sider>
      <Layout>
        <Layout.Header
          style={{ padding: "0 0 0 200px", background: colorBgContainer }}
        >
          <Topbar />
        </Layout.Header>
        <Layout.Content style={{ padding: "0 0 0 200px" }}>
          <div
            style={{
              boxSizing: "border-box",
              margin: "24px",
              padding: "24px",
              height: "calc(100vh - 112px)",
              overflowX: "hidden",
              overflowY: "scroll",
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}
          >
            {children ?? <Outlet />}
          </div>
        </Layout.Content>
      </Layout>
    </Layout>
  );
};

export default Root;
