import { Alert, Empty } from "antd";
import { Bar } from "@ant-design/charts";

interface BarChartProps {
  data: null | undefined | Record<any, any>[];
  entityType: string;
  label?: string;
  count?: string;
  percentage?: string;
}

const BarChart = ({ data, entityType, label, count, percentage }: BarChartProps) => {
  const config = {
    data: Array.isArray(data) ? data : [],
    xField: label || "name",
    yField: count || "count",
    tooltip: {
      name: entityType,
      field: count || "count",
    },
    ...(percentage
      ? {
          label: {
            textAnchor: (d: Record<any, any>) => (d[percentage] > 50 ? 'right' : 'start'),
            fill: (d: Record<any, any>) => (d[percentage] > 50 ? '#fff' : '#000'),

            text: (d: Record<any, any>) =>
              `${((d[percentage] || 0.0) * 1.0).toFixed(2)}%`,
          },
        }
      : {}),
  };

  return (
    <>
      {Array.isArray(data) ? (
        data.length === 0 ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ) : (
          <Bar {...config} />
        )
      ) : (
        <Alert message="Could not load chart data" type="error" />
      )}
    </>
  );
};

export default BarChart;
