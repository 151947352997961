import {
  CheckCircleOutlined,
  QuestionCircleOutlined,
  MinusCircleOutlined,
  InfoCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

interface AdaptorUsageIconProps {
  installed: boolean;
  usage: null | undefined | number;
}

const AdaptorUsageIcon = ({ installed, usage }: AdaptorUsageIconProps) => {
  if (installed) {
    if (Number.isInteger(usage)) {
      if (usage === 0) {
        return <CheckCircleOutlined style={{ color: "#237804" }} />;
      }

      if (usage === 1 || usage === 2) {
        return <QuestionCircleOutlined style={{ color: "#fadb14" }} />;
      }

      if (usage! > 2) {
        return <MinusCircleOutlined style={{ color: "#a8071a" }} />;
      }
    }

    return <InfoCircleOutlined style={{ color: "#006d75" }} />;
  }

  if (Number.isInteger(usage) && usage! <= 1) {
    return <ExclamationCircleOutlined style={{ color: "#ffa940" }} />;
  }

  return <></>;
};

export default AdaptorUsageIcon;
