import { useEffect, useMemo, useState } from "react";

import { Button, Dropdown, Form, Input, Space } from "antd";

import { CaretDownOutlined } from "@ant-design/icons";

interface ParametersEditorProps {
  parameters: Record<string, string>;
  allowedKeys: Array<string>;
  onUpdate: (parameters: Record<string, string>) => void;
}

type LayoutGroup = { [key: string]: Array<{ prefix: string; suffix: string }> };
type Layout = string | LayoutGroup;

const ParametersEditor = ({
  parameters,
  allowedKeys,
  onUpdate,
}: ParametersEditorProps) => {
  const [form] = Form.useForm<any>();

  const [layout, setLayout] = useState<Array<Layout>>([]);
  const [keys, setKeys] = useState<Array<string>>([]);

  useEffect(() => {
    const layout = allowedKeys.reduce((acc, val) => {
      if (val.indexOf("{") > 0 && val.indexOf("}") > 0) {
        const group = val.substring(val.indexOf("{") + 1, val.indexOf("}"));
        const prefix = val.substring(0, val.indexOf("{"));
        const suffix = val.substring(val.indexOf("}") + 1);

        const existent = acc.find(
          (v) => typeof v === "object" && v[group] !== null
        ) as LayoutGroup | undefined;

        if (existent) {
          existent[group].push({ prefix, suffix });
        } else {
          acc.push({ [group]: [{ prefix, suffix }] });
        }
      } else {
        acc.push(val);
      }

      return acc;
    }, [] as Array<Layout>);

    const keys = layout
      .map((row) => {
        if (typeof row === "object") {
          const objKeys = Object.keys(parameters);
          const parts = row[Object.keys(row)[0]];

          const groups = parts
            .map(({ prefix, suffix }) => {
              return objKeys
                .filter((v) => v.startsWith(prefix) && v.endsWith(suffix))
                .map((item) =>
                  item.substring(prefix.length, item.length - suffix.length)
                );
            })
            .flat()
            .filter((value, index, array) => array.indexOf(value) === index);

          return groups
            .map((group) =>
              parts.map(({ prefix, suffix }) => `${prefix}${group}${suffix}`)
            )
            .flat();
        } else {
          return row;
        }
      })
      .flat() as Array<string>;

    setLayout(layout);
    setKeys(keys);

    form.setFieldsValue(parameters);
  }, [form, allowedKeys, parameters]);

  const menuProps = useMemo(
    () => ({
      items: layout
        .filter((row) => typeof row === "object")
        .map((row) => Object.keys(row)[0])
        .map((row) => ({
          label: row,
          key: row,
        })),
      onClick: ({ key }: any) => {
        const prompt = window.prompt(key, "");
        if (prompt) {
          setKeys((prev) => [
            ...prev,
            ...layout
              .filter(
                (row) => typeof row === "object" && Object.keys(row)[0] === key
              )
              .map((row) => (row as LayoutGroup)[Object.keys(row)[0]])
              .flat()
              .map(({ prefix, suffix }) => `${prefix}${prompt}${suffix}`),
          ]);
        }
      },
    }),
    [layout]
  );

  return (
    <>
      {Array.isArray(menuProps?.items) && menuProps.items.length > 0 ? (
        <Dropdown menu={menuProps}>
          <Button>
            <Space>
              Add site credentials
              <CaretDownOutlined />
            </Space>
          </Button>
        </Dropdown>
      ) : null}

      <Form
        form={form}
        layout="horizontal"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        initialValues={{}}
        onFinish={onUpdate}
      >
        {keys.map((key) => {
          return (
            <Form.Item label={key} name={key} key={key}>
              <Input autoComplete="off" />
            </Form.Item>
          );
        })}

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Update
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default ParametersEditor;
