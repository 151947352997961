import { useContext, useMemo, useState } from "react";
import { Link, useOutletContext } from "react-router-dom";

import { useMutation, useQueryClient } from "@tanstack/react-query";

import { UserContext } from "../../context/UserContext";

import { Space, Switch, Table } from "antd";
import {
  LineChartOutlined,
  SettingOutlined,
  CheckOutlined,
  CloseOutlined,
  MinusOutlined,
} from "@ant-design/icons";

import ClientsService, { ClientDetails } from "../../services/Clients";

import AdaptorUsageIcon from "../../components/AdaptorUsageIcon";
import { Adaptor } from "../../services/Adaptors";

const ClientAdaptors = () => {
  const { token } = useContext(UserContext);

  const queryClient = useQueryClient();
  const { client } = useOutletContext<{ client: ClientDetails }>();

  const [adaptors, setAdaptors] = useState<Array<Adaptor>>(client.adaptors);

  const { mutate: mutateVisibility } = useMutation({
    mutationFn: ({ name, visible }: Partial<Adaptor>) =>
      ClientsService.setAdaptorVisibility(token!, client.id, name!, visible!),
    onError: () => {
      console.log(`Could not update visibility`);
    },
    onSuccess: (_data, { name, visible }, _context) => {
      setAdaptors((prev) =>
        prev.map((ad: Adaptor) => (ad.name === name ? { ...ad, visible } : ad))
      );

      queryClient.invalidateQueries({
        queryKey: ["ClientsService.getClient", client?.id],
      });
    },
  });

  const { mutate: mutateGrant } = useMutation({
    mutationFn: ({ name, granted }: Partial<Adaptor>) =>
      ClientsService.setAdaptorGrant(token!, client.id, name!, granted!),
    onError: () => {
      console.log(`Could not update visibility`);
    },
    onSuccess: (_data, { name, granted }, _context) => {
      setAdaptors((prev) =>
        prev.map((ad: Adaptor) => (ad.name === name ? { ...ad, granted } : ad))
      );

      queryClient.invalidateQueries({
        queryKey: ["ClientsService.getClient", client?.id],
      });
    },
  });

  const columns = useMemo<any>(
    () => [
      {
        title: "Usage",
        dataIndex: "usage",
        width: 30,
        render: (_: any, { installed, usage }: Adaptor) => (
          <AdaptorUsageIcon usage={usage} installed={installed!} />
        ),
      },
      {
        title: "Adaptor",
        dataIndex: "name",
      },
      {
        title: "Grant access",
        dataIndex: "granted",
        key: "granted",
        width: 150,
        render: (_: any, { granted, name }: Adaptor) => (
          <Space>
            <Switch
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={
                granted == null ? <MinusOutlined /> : <CloseOutlined />
              }
              disabled={granted == null}
              checked={granted}
              onChange={() => mutateGrant({ name, granted: !granted })}
            />
          </Space>
        ),
      },
      {
        title: "Show adaptor",
        dataIndex: "visible",
        key: "visible",
        width: 150,
        render: (_: any, { visible, installed, name }: Adaptor) => (
          <Space>
            <Switch
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              disabled={installed}
              checked={visible}
              onChange={() =>
                installed ? null : mutateVisibility({ name, visible: !visible })
              }
            />
          </Space>
        ),
      },
      {
        title: "Actions",
        dataIndex: "_actions",
        key: "_actions",
        width: 120,
        render: (_: any, { name }: Adaptor) => (
          <Space align="end">
            <Link to={`/clients/${client.id}/adaptors/${name}/activity`}>
              <LineChartOutlined />
            </Link>
            <Link to={`/clients/${client.id}/adaptors/${name}/parameters`}>
              <SettingOutlined />
            </Link>
          </Space>
        ),
      },
    ],
    [client, mutateGrant, mutateVisibility]
  );

  return (
    <>
      <Table dataSource={adaptors || []} columns={columns} rowKey="name" />
    </>
  );
};

export default ClientAdaptors;
