import { useContext, useState } from "react";
import { Link } from "react-router-dom";

import {
  Breadcrumb,
  Card,
  Col,
  Pagination,
  Row,
  Skeleton,
  Space,
  Image,
  Flex,
} from "antd";
import {
  SettingOutlined,
  LineChartOutlined,
  TeamOutlined,
  WarningOutlined,
  HomeOutlined,
} from "@ant-design/icons";

import { AdaptorsContext } from "../../context/AdaptorsContext";

const Adaptors = () => {
  const { adaptors } = useContext(AdaptorsContext);

  const [page, setPage] = useState<number>(1);

  return (
    <>
      <Breadcrumb
        style={{ marginBottom: "2rem" }}
        items={[
          {
            href: "/",
            title: <HomeOutlined />,
          },
          {
            title: "Adaptors",
          },
        ]}
      />
      <Skeleton loading={adaptors === undefined}>
        <Space direction="vertical" size="middle" style={{ display: "flex" }}>
          <Row gutter={[16, 16]}>
            {adaptors?.slice((page - 1) * 12, page * 12).map((adaptor) => (
              <Col span={6} key={adaptor.name}>
                <Card
                  title={adaptor.title || adaptor.name}
                  extra={
                    adaptor.initialized ? null : (
                      <WarningOutlined style={{ color: "red" }} />
                    )
                  }
                  actions={[
                    <Link to={`/adaptors/${adaptor.name}/activity`}>
                      <LineChartOutlined key="charts" />
                    </Link>,
                    <Link to={`/adaptors/${adaptor.name}/clients`}>
                      <TeamOutlined key="clients" />
                    </Link>,
                    <Link to={`/adaptors/${adaptor.name}/parameters`}>
                      <SettingOutlined key="setting" />
                    </Link>,
                  ]}
                >
                  <Flex justify="center" style={{ height: "48px" }}>
                    <Image
                      height={48}
                      src={`/adaptors/${adaptor.name.toLowerCase}.png`}
                      preview={false}
                    />
                  </Flex>
                </Card>
              </Col>
            ))}
          </Row>

          <Pagination
            defaultCurrent={1}
            total={adaptors?.length || 0}
            defaultPageSize={12}
            onChange={(p) => setPage(p)}
          />
        </Space>
      </Skeleton>
    </>
  );
};

export default Adaptors;
