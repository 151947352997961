import { useMemo } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Root from "./components/layout/Root";

import Adaptor from "./components/layout/outlets/Adaptor";
import Client from "./components/layout/outlets/Client";
import ClientAdaptor from "./components/layout/outlets/ClientAdaptor";

import Home from "./pages/Home";
import Error from "./pages/Error";

import Adaptors from "./pages/adaptor/Adaptors";
import AdaptorActivity from "./pages/adaptor/AdaptorActivity";
import AdaptorClients from "./pages/adaptor/AdaptorClients";
import AdaptorParameters from "./pages/adaptor/AdaptorParameters";

import Clients from "./pages/clients/Clients";
import ClientActivity from "./pages/clients/ClientActivity";
import ClientAdaptors from "./pages/clients/ClientAdaptors";
import ClientAdaptorActivity from "./pages/client-adaptor/ClientAdaptorActivity";
import ClientAdaptorParameters from "./pages/client-adaptor/ClientAdaptorParameters";

const App = () => {
  const router = useMemo(
    () =>
      createBrowserRouter([
        {
          path: "/",
          element: <Root />,
          errorElement: (
            <Root>
              <Error />
            </Root>
          ),
          children: [
            {
              path: "/adaptors",
              element: <Adaptors />,
            },
            {
              path: "/adaptors/:adaptorName",
              element: <Adaptor />,
              children: [
                {
                  path: "/adaptors/:adaptorName/activity",
                  element: <AdaptorActivity />,
                },
                {
                  path: "/adaptors/:adaptorName/clients",
                  element: <AdaptorClients />,
                },
                {
                  path: "/adaptors/:adaptorName/parameters",
                  element: <AdaptorParameters />,
                },
              ],
            },
            {
              path: "/clients",
              element: <Clients />,
            },
            {
              path: "/clients/:clientId",
              element: <Client />,
              children: [
                {
                  path: "/clients/:clientId/activity",
                  element: <ClientActivity />,
                },
                {
                  path: "/clients/:clientId/adaptors",
                  element: <ClientAdaptors />,
                },
              ],
            },
            {
              path: "/clients/:clientId/adaptors/:adaptorName",
              element: <ClientAdaptor />,
              children: [
                {
                  path: "/clients/:clientId/adaptors/:adaptorName/activity",
                  element: <ClientAdaptorActivity />,
                },
                {
                  path: "/clients/:clientId/adaptors/:adaptorName/parameters",
                  element: <ClientAdaptorParameters />,
                },
              ],
            },
            {
              path: "/",
              element: <Home />,
            },
          ],
        },
      ]),
    []
  );

  return <RouterProvider router={router} />;
};

export default App;
