import { useContext, useMemo } from "react";
import { useOutletContext } from "react-router-dom";

import { UserContext } from "../../context/UserContext";
import { AdaptorsContext } from "../../context/AdaptorsContext";
import ParametersEditor from "../../components/ParametersEditor";
import AdaptorsService from "../../services/Adaptors";

const AdaptorParameters = () => {
  const { token } = useContext(UserContext);

  const { getAdaptor } = useContext(AdaptorsContext);

  const { adaptor } = useOutletContext<any>();

  const currentAdaptor = useMemo(() => {
    const { id, name } = adaptor || {};
    return getAdaptor({ id, name });
  }, [adaptor, getAdaptor]);

  return (
    <ParametersEditor
      parameters={adaptor?.parameters || {}}
      allowedKeys={currentAdaptor?.adaptorKeys || []}
      onUpdate={(value) =>
        AdaptorsService.setParameters(token!, adaptor.name, value).then(
          () => (adaptor.parameters = value)
        )
      }
    />
  );
};

export default AdaptorParameters;
