import { Alert } from "antd";
import { useRouteError } from "react-router-dom";

const Error = () => {
  const error = useRouteError() as any;
  console.error(error);

  return (
    <>
      <Alert
        message="Sorry, an unexpected error has occurred."
        description={error.statusText || error.message}
        type="error"
        showIcon
      />
    </>
  );
};

export default Error;
