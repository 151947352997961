export const Months: Record<string, string> = {
  "01": "Jan",
  "02": "Feb",
  "03": "Mar",
  "04": "Apr",
  "05": "May",
  "06": "Jun",
  "07": "Jul",
  "08": "Aug",
  "09": "Sep",
  "10": "Oct",
  "11": "Nov",
  "12": "Dec",
};

export const monthLabelFormatter = (val: string) => {
  const [sYear, sMonth] = val.split("-");
  return `${Months[sMonth]} ${sYear}`;
};

export const intervals = (start: string, end: string) => {
  if (!start || !end) {
    return [];
  }

  const [sYear, sMonth] = start.split("-").map((v) => parseInt(v, 10));
  const [eYear, eMonth] = end.split("-").map((v) => parseInt(v, 10));

  const pit = [start];

  if (sYear === eYear && sMonth === eMonth) {
    return pit;
  }

  let year = sYear;
  let month = sMonth;

  do {
    month += 1;
    year = month > 12 ? year + 1 : year;
    month = month > 12 ? 1 : month;
    pit.push(`${year}-${month < 10 ? "0" : ""}${month}`);

    if (year === eYear && month === eMonth) {
      break;
    }
  } while (pit.length < 100);

  if (pit.length >= 100) {
    return [];
  }

  return pit;
};

export const calculatePercentages = (
  totals: Array<Record<any, any>>,
  errors: Array<Record<any, any>>,
  countField: string,
  percentageField: string,
  compareFn: (v1: Record<any, any>, v2: Record<any, any>) => boolean
) => {
  if (Array.isArray(totals) && Array.isArray(errors)) {
    return errors.map((v: Record<any, any>) => {
      const total = totals.find((t) => compareFn(v, t));
      return {
        ...v,
        [percentageField]:
          total && total[countField] > 0
            ? (((v[countField] || 0) / total[countField]) * 100).toFixed(2)
            : (0.0).toFixed(2),
      };
    });
  }

  return undefined;
};
