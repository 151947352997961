import { useMemo } from "react";
import { NavLink, useOutletContext } from "react-router-dom";

import { Space, Table } from "antd";
import { LineChartOutlined } from "@ant-design/icons";

const AdaptorClients = () => {
  const { adaptor } = useOutletContext<any>();

  const columns = useMemo(
    () => [
      {
        title: "Customer",
        dataIndex: "name",
        render: (
          name: string,
          {
            id,
            usage,
            installed,
          }: {
            id: number;
            usage: null | undefined | number;
            installed: boolean;
          }
        ) => (
          <Space>
            <NavLink to={`/clients/${id}`}>{name}</NavLink>
          </Space>
        ),
      },
      {
        title: "Actions",
        dataIndex: "_actions",
        key: "_actions",
        width: 120,
        render: (_: any, { id }: { id: number }) => (
          <Space align="end">
            <NavLink to={`/clients/${id}/adaptors/${adaptor.name}`}>
              <LineChartOutlined />
            </NavLink>
          </Space>
        ),
      },
    ],
    [adaptor]
  );

  return (
    <>
      <Table dataSource={adaptor.clients || []} columns={columns} rowKey="id" />
    </>
  );
};

export default AdaptorClients;
