import React, { useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { MenuProps, Menu } from "antd";

import {
  DashboardOutlined,
  ApiOutlined,
  TeamOutlined,
} from "@ant-design/icons";

const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const items: MenuProps["items"] = useMemo(
    () => [
      {
        key: "/",
        icon: React.createElement(DashboardOutlined),
        label: "Dashboard",
      },
      {
        key: "/clients",
        icon: React.createElement(TeamOutlined),
        label: "Customers",
      },
      {
        key: "/adaptors",
        icon: React.createElement(ApiOutlined),
        label: "Adaptors",
      },
    ],
    []
  );

  const selectedKeys = useMemo(() => {
    return items
      .filter((item) =>
        item!.key! === "/"
          ? location.pathname === item!.key!
          : location.pathname.startsWith(item!.key!.toString())
      )
      .map((item) => item!.key!.toString());
  }, [items, location]);

  const handleSelect = useCallback(
    ({ key }: { key: string }) => {
      navigate(key);
    },
    [navigate]
  );

  return (
    <Menu
      theme="dark"
      items={items}
      onSelect={handleSelect}
      selectedKeys={selectedKeys}
    />
  );
};

export default Sidebar;
