import { get } from "./http";

export type Count = {
  name: string;
  count: number;
};

export type MonthlyCount = {
  month: string;
  count: number;
};

export type MonthlyActionCount = {
  month: string;
  action: string;
  count: number;
};

type ChartsServiceType = {
  getAllActivitiesByAdaptors: (token: string) => Promise<Array<Count>>;
  getFailedActivitiesByAdaptors: (token: string) => Promise<Array<Count>>;

  getAllActivitiesByClients: (token: string) => Promise<Array<Count>>;
  getFailedActivitiesByClients: (token: string) => Promise<Array<Count>>;

  getAllActivitiesForClientAndAdaptor: (
    token: string,
    clientId: number,
    adaptorName: string
  ) => Promise<Array<MonthlyCount>>;

  getFailedActivitiesForClientAndAdaptor: (
    token: string,
    clientId: number,
    adaptorName: string
  ) => Promise<Array<MonthlyCount>>;

  getAllActionsForClientAndAdaptor: (
    token: string,
    clientId: number,
    adaptorName: string
  ) => Promise<Array<MonthlyActionCount>>;

  getFailedActionsForClientAndAdaptor: (
    token: string,
    clientId: number,
    adaptorName: string
  ) => Promise<Array<MonthlyActionCount>>;

  getAllActivitiesForAdaptorByClients: (
    token: string,
    adaptorName: string
  ) => Promise<Array<Count>>;

  getFailedActivitiesForAdaptorByClients: (
    token: string,
    adaptorName: string
  ) => Promise<Array<Count>>;

  getAllClientActivitiesForAdaptors: (
    token: string,
    clientId: number
  ) => Promise<Array<Count>>;

  getFailedClientActivitiesForAdaptors: (
    token: string,
    clientId: number
  ) => Promise<Array<Count>>;
};

const ChartsService: ChartsServiceType = {
  getAllActivitiesByAdaptors: (token) =>
    get(token)("/admin/charts/activities/all/adaptors"),

  getFailedActivitiesByAdaptors: (token) =>
    get(token)("/admin/charts/activities/failed/adaptors"),

  getAllActivitiesByClients: (token) =>
    get(token)("/admin/charts/activities/all/clients"),

  getFailedActivitiesByClients: (token) =>
    get(token)("/admin/charts/activities/failed/clients"),

  getAllActivitiesForClientAndAdaptor: (token, clientId, adaptorName) =>
    get(token)(
      `/admin/charts/activities/all/client/${clientId}/adaptor/${adaptorName}`
    ),

  getFailedActivitiesForClientAndAdaptor: (token, clientId, adaptorName) =>
    get(token)(
      `/admin/charts/activities/failed/client/${clientId}/adaptor/${adaptorName}`
    ),

  getAllActionsForClientAndAdaptor: (token, clientId, adaptorName) =>
    get(token)(
      `/admin/charts/actions/all/client/${clientId}/adaptor/${adaptorName}`
    ),
  getFailedActionsForClientAndAdaptor: (token, clientId, adaptorName) =>
    get(token)(
      `/admin/charts/actions/failed/client/${clientId}/adaptor/${adaptorName}`
    ),

  getAllActivitiesForAdaptorByClients: (token, adaptorName) =>
    get(token)(`/admin/charts/activities/all/adaptor/${adaptorName}/clients`),

  getFailedActivitiesForAdaptorByClients: (token, adaptorName) =>
    get(token)(
      `/admin/charts/activities/failed/adaptor/${adaptorName}/clients`
    ),

  getAllClientActivitiesForAdaptors: (token, clientId) =>
    get(token)(`/admin/charts/activities/all/client/${clientId}/adaptors`),

  getFailedClientActivitiesForAdaptors: (token, clientId) =>
    get(token)(`/admin/charts/activities/failed/client/${clientId}/adaptors`),
};

export default ChartsService;
