import { useContext } from "react";

import { Card, Col, Row, Typography } from "antd";

import { useQuery } from "@tanstack/react-query";

import { UserContext } from "../context/UserContext";

import ChartsService from "../services/Charts";

import BarChart from "../components/charts/BarChart";
import TopOrAllTabs from "../components/TopOrAllTabs";

const Home = () => {
  const { token } = useContext(UserContext);

  const activitiesByAdaptors = useQuery({
    queryKey: ["ChartsService.getAllActivitiesByAdaptors"],
    queryFn: () => ChartsService.getAllActivitiesByAdaptors(token!),
  });

  const errorsByAdaptors = useQuery({
    queryKey: ["ChartsService.getFailedActivitiesByAdaptors"],
    queryFn: () => ChartsService.getFailedActivitiesByAdaptors(token!),
  });

  const activitiesByClients = useQuery({
    queryKey: ["ChartsService.getAllActivitiesByClients"],
    queryFn: () => ChartsService.getAllActivitiesByClients(token!),
  });

  const errorsByClients = useQuery({
    queryKey: ["ChartsService.getFailedActivitiesByClients"],
    queryFn: () => ChartsService.getFailedActivitiesByClients(token!),
  });

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Card loading={activitiesByAdaptors.isFetching}>
            <Typography.Title level={5} style={{ marginTop: 0 }}>
              Total Business Activities by Adapter
            </Typography.Title>

            <TopOrAllTabs
              count={5}
              data={activitiesByAdaptors.data}
              field="count"
              component={(d) => <BarChart entityType="Activities" data={d} />}
            />
          </Card>
        </Col>
        <Col span={12}>
          <Card loading={errorsByAdaptors.isFetching}>
            <Typography.Title level={5} style={{ marginTop: 0 }}>
              Failed Business Activities by Adapter
            </Typography.Title>

            <TopOrAllTabs
              count={5}
              data={errorsByAdaptors.data}
              field="count"
              component={(d) => <BarChart entityType="Activities" data={d} />}
            />
          </Card>
        </Col>
        <Col span={12}>
          <Card loading={activitiesByClients.isFetching}>
            <Typography.Title level={5} style={{ marginTop: 0 }}>
              Total Business Activities by Client
            </Typography.Title>

            <TopOrAllTabs
              count={5}
              data={activitiesByClients.data}
              field="count"
              component={(d) => <BarChart entityType="Activities" data={d} />}
            />
          </Card>
        </Col>
        <Col span={12}>
          <Card loading={errorsByClients.isFetching}>
            <Typography.Title level={5} style={{ marginTop: 0 }}>
              Failed Business Activities by Client
            </Typography.Title>

            <TopOrAllTabs
              count={5}
              data={errorsByClients.data}
              field="count"
              component={(d) => <BarChart entityType="Activities" data={d} />}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Home;
