import { useContext, useMemo } from "react";
import { Link, NavLink } from "react-router-dom";

import { useQuery } from "@tanstack/react-query";

import { Flex, Space, Table, Tabs } from "antd";
import { LineChartOutlined, ApiOutlined } from "@ant-design/icons";

import { UserContext } from "../../context/UserContext";

import ClientsAdaptorsMap from "../../components/clients/ClientsAdaptorsMap";
import CreateCustomerButton from "../../components/clients/CreateCustomerButton";

import ClientsService from "../../services/Clients";

const Clients = () => {
  const { token } = useContext(UserContext);

  const { data: clients } = useQuery({
    queryKey: ["ClientsService.getClients"],
    queryFn: () => ClientsService.getClients(token!),
  });

  const columns = useMemo(
    () => [
      {
        title: "Customer",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Actions",
        dataIndex: "_actions",
        key: "_actions",
        width: 120,
        render: (_: any, { id }: { id: number }) => (
          <>
            <Space align="end">
              <NavLink to={`/clients/${id}/activity`}>
                <LineChartOutlined />
              </NavLink>
              <Link to={`/clients/${id}/adaptors`}>
                <ApiOutlined />
              </Link>
            </Space>
          </>
        ),
      },
    ],
    []
  );

  const tabs = useMemo(
    () => [
      {
        label: "Clients",
        key: "list",
        children: (
          <>
            <Flex
              justify="flex-end"
              align="center"
              gap="small"
              style={{ marginBottom: "1rem" }}
            >
              <CreateCustomerButton />
            </Flex>

            <Table dataSource={clients} columns={columns} rowKey="id" />
          </>
        ),
      },
      {
        label: "Usage map",
        key: "map",
        children: <ClientsAdaptorsMap />,
      },
    ],
    [clients, columns]
  );

  return <Tabs defaultActiveKey="0" items={tabs} />;
};

export default Clients;
