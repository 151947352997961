import {
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useState,
  useMemo,
  useContext,
} from "react";

import { UserContext } from "./UserContext";

import AdaptorsService, { Adaptor } from "../services/Adaptors";

type AdaptorLookupParam = {
  id?: number;
  name?: string;
};

type AdaptorLookupFn = (param: AdaptorLookupParam) => Adaptor | null;

type AdaptorsContextType = {
  adaptors: Array<Adaptor> | null;
  getAdaptor: AdaptorLookupFn;
  refresh: () => void;
};

export const AdaptorsContext = createContext<AdaptorsContextType>({
  adaptors: null,
  getAdaptor: () => null,
  refresh: () => null,
});

const AdaptorsContextProvider = ({ children }: { children: ReactNode }) => {
  const { token } = useContext(UserContext);

  const [version, setVersion] = useState<number>(0);
  const [adaptors, setAdaptors] = useState<Array<Adaptor> | null>(null);

  const getAdaptor: AdaptorLookupFn = useCallback(
    ({ id, name }: AdaptorLookupParam) =>
      Array.isArray(adaptors)
        ? adaptors.find((a) => a.id === id || a.name === name) ?? null
        : null,
    [adaptors]
  );

  useEffect(() => {
    AdaptorsService.getAdaptors(token!).then((adaptors) =>
      setAdaptors(adaptors)
    );
  }, [token, version]);

  const refresh = useCallback(() => setVersion((prev) => prev + 1), []);

  const value = useMemo(
    () => ({ adaptors, getAdaptor, refresh }),
    [adaptors, getAdaptor, refresh]
  );

  return (
    <AdaptorsContext.Provider value={value}>
      {Array.isArray(adaptors) ? <>{children}</> : <>Loading...</>}
    </AdaptorsContext.Provider>
  );
};

export default AdaptorsContextProvider;
