import { useContext, useMemo } from "react";
import { useOutletContext } from "react-router-dom";

import { useQuery } from "@tanstack/react-query";

import { Card, Col, Row, Typography } from "antd";

import { UserContext } from "../../context/UserContext";

import GroupedMonthlyBarChart from "../../components/charts/GroupedMonthlyBarChart";
import MonthlyBarChart from "../../components/charts/MonthlyBarChart";

import ChartsService from "../../services/Charts";

import { calculatePercentages } from "../../Utils";

const ClientAdaptorActivity = () => {
  const { token } = useContext(UserContext);

  const { client, adaptor } = useOutletContext<any>();

  const { data: activitiesByAdaptors } = useQuery({
    queryKey: [
      "ChartsService.getAllActivitiesForClientAndAdaptor",
      client.id,
      adaptor.name,
    ],
    queryFn: () =>
      ChartsService.getAllActivitiesForClientAndAdaptor(
        token!,
        client.id,
        adaptor.name
      ),
  });

  const { data: errorsByAdaptors } = useQuery({
    queryKey: [
      "ChartsService.getFailedActivitiesForClientAndAdaptor",
      client.id,
      adaptor.name,
    ],
    queryFn: () =>
      ChartsService.getFailedActivitiesForClientAndAdaptor(
        token!,
        client.id,
        adaptor.name
      ),
  });

  const errorsByAdaptorsWithPercentages = useMemo(
    () =>
      calculatePercentages(
        activitiesByAdaptors!,
        errorsByAdaptors!,
        "count",
        "percentage",
        (v1, v2) => v1.month === v2.month
      ),
    [activitiesByAdaptors, errorsByAdaptors]
  );

  const { data: totalActionsByAdaptors } = useQuery({
    queryKey: [
      "ChartsService.getAllActionsForClientAndAdaptor",
      client.id,
      adaptor.name,
    ],
    queryFn: () =>
      ChartsService.getAllActionsForClientAndAdaptor(
        token!,
        client.id,
        adaptor.name
      ),
  });

  const { data: failedActionsByAdaptors } = useQuery({
    queryKey: [
      "ChartsService.getFailedActionsForClientAndAdaptor",
      client.id,
      adaptor.name,
    ],
    queryFn: () =>
      ChartsService.getFailedActionsForClientAndAdaptor(
        token!,
        client.id,
        adaptor.name
      ),
  });

  const activitiesCount = useMemo(() => {
    if (!Array.isArray(totalActionsByAdaptors)) {
      return 0;
    }

    return totalActionsByAdaptors
      .map((v) => v.action)
      .filter((v, idx, arr) => arr.indexOf(v) === idx).length;
  }, [totalActionsByAdaptors]);

  const failedActionsByAdaptorsWithPercentages = useMemo(
    () =>
      calculatePercentages(
        totalActionsByAdaptors!,
        failedActionsByAdaptors!,
        "count",
        "percentage",
        (v1, v2) => v1.month === v2.month && v1.action === v2.action
      ),
    [totalActionsByAdaptors, failedActionsByAdaptors]
  );

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Card loading={activitiesByAdaptors === undefined}>
            <Typography.Title level={5} style={{ marginTop: 0 }}>
              Total Business Activities by Adapter
            </Typography.Title>

            <MonthlyBarChart
              data={activitiesByAdaptors}
              entityType="Activities"
              label="month"
            />
          </Card>
        </Col>
        <Col span={12}>
          <Card loading={errorsByAdaptorsWithPercentages === undefined}>
            <Typography.Title level={5} style={{ marginTop: 0 }}>
              Failed Business Activities by Adapter
            </Typography.Title>

            <MonthlyBarChart
              data={errorsByAdaptorsWithPercentages}
              entityType="Activities"
              label="month"
              percentage="percentage"
            />
          </Card>
        </Col>

        {activitiesCount > 1 ? (
          <>
            <Col span={12}>
              <Card loading={totalActionsByAdaptors === undefined}>
                <Typography.Title level={5} style={{ marginTop: 0 }}>
                  Total Business Activities by Adapter
                </Typography.Title>

                <GroupedMonthlyBarChart data={totalActionsByAdaptors} />
              </Card>
            </Col>
            <Col span={12}>
              <Card
                loading={failedActionsByAdaptorsWithPercentages === undefined}
              >
                <Typography.Title level={5} style={{ marginTop: 0 }}>
                  Failed Business Activities by Adapter
                </Typography.Title>

                <GroupedMonthlyBarChart
                  data={failedActionsByAdaptorsWithPercentages}
                  percentage="percentage"
                />
              </Card>
            </Col>
          </>
        ) : null}
      </Row>
    </>
  );
};

export default ClientAdaptorActivity;
