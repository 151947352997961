import { ReactNode, createContext, useEffect, useState } from "react";

import { SignOut } from "@aws-amplify/ui-react/dist/types/components/Authenticator/Authenticator";
import { AuthUser, fetchAuthSession } from "aws-amplify/auth";

type UserContextType = {
  signOut: SignOut;
  user: AuthUser;
  token: string | null;
};

const UnauthenticatedUser: UserContextType = {
  signOut: () => null,
  user: { username: "", userId: "" },
  token: null,
};

export const UserContext = createContext<UserContextType>(UnauthenticatedUser);

const UserContextProvider = ({
  signOut,
  user,
  children,
}: {
  signOut?: SignOut;
  user?: AuthUser;
  children: ReactNode;
}) => {
  const [token, setToken] = useState<string | null>(null);
  useEffect(() => {
    fetchAuthSession()
      .then((res) => {
        const { tokens } = res;
        const { idToken } = tokens!;
        setToken(idToken ? idToken.toString() : null);
      })
      .catch((err) => {
        console.error(err);
        setToken(null);
      });
  }, []);

  return (
    <UserContext.Provider
      value={{ user: user!, signOut: signOut!, token: token }}
    >
      {token == null ? <>Loading...</> : <>{children}</>}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
