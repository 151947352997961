import { useContext } from "react";
import { useOutletContext } from "react-router-dom";

import { useQuery } from "@tanstack/react-query";

import { Card, Col, Row, Typography } from "antd";

import { UserContext } from "../../context/UserContext";

import BarChart from "../../components/charts/BarChart";

import ChartsService from "../../services/Charts";
import TopOrAllTabs from "../../components/TopOrAllTabs";

const ClientActivity = () => {
  const { token } = useContext(UserContext);
  const { client } = useOutletContext<any>();

  const { data: activitiesByAdaptors } = useQuery({
    queryKey: ["ChartsService.getAllClientActivitiesForAdaptors", client.id],
    queryFn: () =>
      ChartsService.getAllClientActivitiesForAdaptors(token!, client.id),
    enabled: !!client,
  });

  const { data: errorsByAdaptors } = useQuery({
    queryKey: ["ChartsService.getFailedClientActivitiesForAdaptors", client.id],
    queryFn: () =>
      ChartsService.getFailedClientActivitiesForAdaptors(token!, client.id),
    enabled: !!client,
  });

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Card loading={activitiesByAdaptors === undefined}>
            <Typography.Title level={5} style={{ marginTop: 0 }}>
              Total Business Activities by Adapter
            </Typography.Title>

            <TopOrAllTabs
              count={5}
              data={activitiesByAdaptors}
              field="count"
              component={(d) => <BarChart entityType="Activities" data={d} />}
            />
          </Card>
        </Col>
        <Col span={12}>
          <Card loading={errorsByAdaptors === undefined}>
            <Typography.Title level={5} style={{ marginTop: 0 }}>
              Failed Business Activities by Adapter
            </Typography.Title>

            <TopOrAllTabs
              count={5}
              data={errorsByAdaptors}
              field="count"
              component={(d) => <BarChart entityType="Activities" data={d} />}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ClientActivity;
