import Config from "../Config";

const handleJsonResponse = (res: Response) => {
  if (res.ok) {
    return res.json();
  }
  return Promise.reject({ status: res.status, text: res.text });
};

export const get = (token: string) => (endpoint: string) =>
  fetch(
    `${Config.apiBaseUrl}${endpoint.startsWith("/") ? "" : "/"}${endpoint}`,
    {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  ).then(handleJsonResponse);

export const post = (token: string) => (endpoint: string, payload: any) =>
  fetch(
    `${Config.apiBaseUrl}${endpoint.startsWith("/") ? "" : "/"}${endpoint}`,
    {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    }
  ).then(handleJsonResponse);

export const put = (token: string) => (endpoint: string, payload: any) =>
  fetch(
    `${Config.apiBaseUrl}${endpoint.startsWith("/") ? "" : "/"}${endpoint}`,
    {
      method: "PUT",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    }
  ).then(handleJsonResponse);

export const remove = (token: string) => (endpoint: string) =>
  fetch(
    `${Config.apiBaseUrl}${endpoint.startsWith("/") ? "" : "/"}${endpoint}`,
    {
      method: "DELETE",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  ).then(handleJsonResponse);
