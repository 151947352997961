import { useContext, useMemo } from "react";
import { Link } from "react-router-dom";

import { useQuery } from "@tanstack/react-query";

import { Table } from "antd";

import { UserContext } from "../../context/UserContext";
import { AdaptorsContext } from "../../context/AdaptorsContext";

import AdaptorUsageLegend from "../AdaptorUsageLegend";
import AdaptorUsageIcon from "../AdaptorUsageIcon";

import ClientsService from "../../services/Clients";

const ConnectionCell =
  (clientId: number) =>
  (
    value: { installed: boolean; usage: undefined | null | number },
    row: { adaptorName: string }
  ) =>
    (
      <>
        {value == null ? (
          ""
        ) : (
          <Link to={`/clients/${clientId}/adaptors/${row.adaptorName}`}>
            <AdaptorUsageIcon {...value} />
          </Link>
        )}
      </>
    );

const ClientsAdaptorsMap = () => {
  const { token } = useContext(UserContext);

  const { getAdaptor } = useContext(AdaptorsContext);

  const { data: result } = useQuery({
    queryKey: ["ClientsService.getClientsAdaptorsMap"],
    queryFn: () => ClientsService.getClientsAdaptorsMap(token!),
  });

  const datasource = useMemo(() => {
    return result?.reduce(
      (acc: Array<Record<string, any>>, row: Record<string, any>) => {
        const {
          client: { id: clientId },
          adaptor: { name: adaptorName, usage, installed },
        } = row;

        let obj = acc.find(
          (curr: Record<string, any>) => curr.adaptorName === adaptorName
        );

        if (!obj) {
          obj = {
            adaptorName,
            adaptorTitle:
              getAdaptor({ name: adaptorName })?.title || adaptorName,
          };
          acc.push(obj);
        }

        obj[`client_${clientId}`] = { usage, installed };

        return acc;
      },
      []
    );
  }, [getAdaptor, result]);

  const columns = useMemo(
    () => [
      {
        title: "Adaptor",
        dataIndex: "adaptorName",
        key: "adaptorName",
        width: 200,
        fixed: "left",
        render: (name: string) => <>{name}</>,
      },
      ...(result || []).reduce((acc: Array<any>, row: Record<any, any>) => {
        const {
          client: { id: clientId, name: clientName },
        } = row;

        if (
          acc.findIndex((curr: { key: number }) => curr.key === clientId) === -1
        ) {
          acc.push({
            title: clientName,
            dataIndex: `client_${clientId}`,
            key: clientId,
            width: 80,
            ellipsis: {
              showTitle: false,
            },
            render: ConnectionCell(clientId),
          });
        }

        return acc;
      }, []),
    ],
    [result]
  );

  return (
    <>
      <AdaptorUsageLegend />
      <Table
        dataSource={datasource || []}
        columns={columns || []}
        pagination={false}
        scroll={{
          x: 200 + ((columns?.length || 1) - 1) * 80,
          y: "calc(100vh - 300px)",
        }}
        rowKey="adaptorName"
      />
    </>
  );
};

export default ClientsAdaptorsMap;
